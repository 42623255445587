import React from 'react';
import { useStaticQuery, graphql, Link } from "gatsby"

const Category = () => {
	const query = useStaticQuery(graphql`
		query {
			allStrapiCategories(sort: {fields: created_at, order: DESC}, limit:6){
				edges{
					node{
						id
						name
					}
				}
			}
		}
	`)
	const categories = [];
	query.allStrapiCategories.edges.forEach(({node})=>{
		categories.push(node);
	})
	return(
		<div className="categories single-widget">
		  <h4>Categories</h4>
		  <ul>
			  {
			  	categories.map((item, index)=>{
			  		return( <li key={index}><Link to={item.id}>{item.name}</Link></li> )
			  	})
			  }
		  </ul>
		</div>
	)
}

export default Category
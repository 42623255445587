import React from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"
import blogAvatar from '../../images/blog-default.jpg'

const LatestBlog = () => {
	const query = useStaticQuery(graphql`
		query {
			allStrapiPosts(sort: {fields: created_at, order: DESC}, limit:4){
				edges{
					node{
						id
						post_title
						post_excerpt
						created_at
						feature_image {
						  childImageSharp {
						    fluid {
						      src
						    }
						  }
						}
					}
				}
			}
		}
	`)
	const posts = [];
	query.allStrapiPosts.edges.forEach(({node})=>{
		posts.push(node);
	})
	return(
		<div className="widget-latest-post single-widget">
		  <h4>Latest Post</h4>
		  <ul className="latest-post">
		  	{
		  		posts.map((item, index)=>{
		  			return(
		  				<li className="single-latest-post" key={index}>
		  				  <div className="latest-post-img">
		  				    <Link to={item.id}><img src={item.feature_image ? item.feature_image.childImageSharp.fluid.src : blogAvatar} className="img-fluid" alt={item.post_title}/></Link>
		  				  </div>
		  				  <h5><Link to={item.id}>{`${item.post_title.substring(0, 45)}...`}</Link></h5>
		  				  <p>{new Date(item['created_at']).toLocaleString()}</p>
		  				</li>
		  			)
		  		})
		  	}
		  </ul>
		</div>
	)
}

export default LatestBlog;
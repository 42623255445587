import React from 'react';
import LatestBlog from './latest-blog'
import Categories from './categories'
import Tags from './tags'

const SideBarIndex = ()=>{
	return(
		<div className="col-lg-4 col-md-12 col-xs-12">
		  <div className="widgets">
		    <LatestBlog/>
		    <Categories/>
		    <Tags/>
		  </div>
		</div>
	)
}

export default SideBarIndex;
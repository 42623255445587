import React from 'react';
import { useStaticQuery, graphql, Link } from "gatsby"

const Tags = () => {
	const query = useStaticQuery(graphql`
		query {
			allStrapiTags(sort: {fields: created_at, order: DESC}, limit:6){
				edges{
					node{
						id
						name
					}
				}
			}
		}
	`)
	const tags = [];
	query.allStrapiTags.edges.forEach(({node})=>{
		tags.push(node);
	})
	return (
		<div className="tags single-widget">
		  <h4>Tags</h4>
		  <ul>
		  	{
		  		tags.map((item, index)=>{
		  			return(
		  				<li key={index}><Link to={item.id}>{item.name}</Link></li>
		  			)
		  		})
		  	}
		  </ul>
		</div>
	)
}

export default Tags;
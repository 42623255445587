import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo";
import SubHeader from '../components/subheader';
import { graphql } from 'gatsby'
import Markdown from 'react-markdown'
//import ClientSection from '../components/clients'
import SideBar from '../components/sidebar/index'

const PostTemplate = ({data}) => {
    return (
        <Layout>
            <SEO title={data.strapiPosts.post_title} />
            <SubHeader title={data.strapiPosts.post_title}/>
            <div id="blog-single">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 col-md-8 col-xs-12">
                    <div className="blog-post">
                      <div className="post-thumb">
                          <img src={data.strapiPosts.feature_image ? data.strapiPosts.feature_image.childImageSharp.fluid.src : null} alt=""/>
                      </div>
                      <div className="post-content">
                        <h3>{data.strapiPosts.post_title}</h3>
                        <Markdown source={data.strapiPosts.post_content} escapeHtml={true}/>
                      </div>
                    </div>
                  </div>
                  <SideBar/>
                </div>
              </div>
            </div>
        </Layout>
    )
}

export default PostTemplate;

export const query = graphql`
  query PostTemplate($id: String!) {
    strapiPosts(id: { eq: $id }) {
      id
      post_title
      post_excerpt
      post_content
      feature_image {
        childImageSharp {
          fluid {
            src
          }
        }
      }
    }
  }
` 